export const SideBarIcons: { key: string; icon: string }[] = [
  {
    key: 'dashboard',
    icon: 'mdi-view-dashboard',
  },
  {
    key: 'administration',
    icon: 'mdi-store-cog',
  },
  {
    key: 'access',
    icon: 'mdi-access-point',
  },
  {
    key: 'communication',
    icon: 'mdi-email',
  },
  {
    key: 'clients',
    icon: 'mdi-account-group',
  },
  {
    key: 'groups',
    icon: 'mdi-account-group',
  },
  {
    key: 'deposit_accounts',
    icon: 'mdi-currency-usd',
  },
  {
    key: 'loan_accounts',
    icon: 'mdi-wallet',
  },
  {
    key: 'credit_arrangements',
    icon: 'mdi-handshake',
  },
  {
    key: 'shares',
    icon: 'mdi-chart-line',
  },
  {
    key: 'assets',
    icon: 'mdi-warehouse',
  },
  {
    key: 'hr',
    icon: 'mdi-account',
  },
];
