<template>
  <v-app>
    <v-snackbar
      :color="$store.state.snackbar.color"
      :model-value="!!$store.state.snackbar.message"
      :timeout="5000"
      location="top right"
      update:model-value=""
      variant="elevated"
    >
      {{ $store.state.snackbar.message }}
    </v-snackbar>
    <router-view />
  </v-app>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, watch } from 'vue';
  import { SNACKBAR_MODULE, useStore } from '@/store';
  import { useRoute, useRouter } from 'vue-router';
  import { SET_SNACKBAR_STATUS } from '@/store/modules/snackbar';

  export default defineComponent({
    name: 'App',
    components: {},
    setup() {
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const authUser = computed(() => store.state.auth.profile.user_details);
      const snackbarMessage = computed(() => store.state.snackbar.message);
      const isAuthenticated = computed(
        () => store?.state?.auth?.isAuthenticated
      );

      onMounted(() => {
        store.commit(`${SNACKBAR_MODULE}/${SET_SNACKBAR_STATUS}`, {
          message: null,
          color: null,
        });
      });

      watch(snackbarMessage, async (value) => {
        if (value) {
          setTimeout(() => {
            store.commit(`${SNACKBAR_MODULE}/${SET_SNACKBAR_STATUS}`, {
              message: null,
              color: null,
            });
          }, 5000);
        }
      });

      watch(isAuthenticated, async (value) => {
        if (!value) {
          await router.push({
            path: route.params.alias ? `/c/${route.params.alias}/login` : '/',
            query: route.params.alias
              ? {
                  from: router.options?.history?.location,
                }
              : {},
          });
        } else {
          if (authUser.value.role_type === 'ADMINISTRATOR') {
            //
          }
        }
      });

      return;
    },
  });
</script>
<style lang="scss">
  #app {
    font-family: $body-font-family, sans-serif !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .text-h5 {
    font-family: $body-font-family, sans-serif !important;
  }
</style>
