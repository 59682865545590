import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'landing-page',
    component: () => import('@/views/landing-page/Index.vue'),
    meta: { title: 'About Pinkapple' },
  },
  {
    path: '/c/:alias',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'login' },
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/auth/Login.vue'),
        meta: { title: 'Login to Pinkapple' },
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/auth/Signup.vue'),
        meta: { title: 'Register User Pinkapple' },
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/layouts/ParentLayout.vue'),
        meta: { title: 'Pinkapple Dashboard' },
        children: [
          {
            path: 'dashboard',
            name: 'dashboard',
            redirect: { name: 'generaldashboard' },
          },
          {
            path: 'generaldashboard',
            name: 'generaldashboard',
            component: () => import('@/views/dashboard/Index.vue'),
            meta: { title: 'Pinkapple general dashboard' },
          },
          {
            path: 'tellerdashboard',
            component: () => import('@/views/dashboard/teller.vue'),
            meta: { title: 'Pinkapple teller dashboard' },
          },
          {
            path: 'loanofficerdashboard',
            component: () => import('@/views/dashboard/loanOfficer.vue'),
            meta: { title: 'Pinkapple loan officer dashboard' },
          },
        ],
      },
      {
        path: 'administration',
        component: () => import('@/layouts/ParentLayout.vue'),
        meta: { title: 'Pinkapple Administration' },
        children: [
          {
            path: '',
            name: 'administration',
            redirect: { name: 'general-setup-orgnisation_details' },
          },
          {
            path: 'general_setup',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Administration  - General Setup' },
            children: [
              {
                path: '',
                name: 'administration-general_setup',
                redirect: { name: 'general-setup-orgnisation_details' },
              },
              {
                path: 'orgnisation_details',
                name: 'general-setup-orgnisation_details',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title: 'Pinkapple General Setup - ORGANISATION DETAILS',
                },
              },
              {
                path: 'holidays',
                name: 'general-setup-holidays',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple General Setup - HOLIDAYS' },
              },
              {
                path: 'client_type',
                name: 'general-setup-client_type',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple General Setup - CLIENT TYPE' },
              },
              {
                path: 'business_unit_type',
                name: 'general-setup-business_unit_type',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title: 'Pinkapple General Setup - BUSINESS UNIT TYPE',
                },
              },
              {
                path: 'id_templates',
                name: 'general-setup-id_templates',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple General Setup - ID TEMPLATES' },
              },
              {
                path: 'internal',
                name: 'general-setup-internal',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple General Setup - INTERNAL' },
              },
              {
                path: 'labels',
                name: 'general-setup-labels',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple General Setup - LABELS' },
              },
              {
                path: 'group_roles',
                name: 'general-setup-group_roles',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple General Setup - GROUP ROLES' },
              },
              {
                path: 'branding',
                name: 'general-setup-branding',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple General Setup - BRANDING' },
              },
            ],
          },
          {
            path: 'financial_setup',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Administration  - Financial Setup' },
            children: [
              {
                path: '',
                name: 'administration-financial_setup',
                redirect: { name: 'financial_setup-transaction_channels' },
              },
              {
                path: 'transaction_channels',
                name: 'financial_setup-transaction_channels',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title: 'Pinkapple Administration - TRANSACTION CHANNELS',
                },
              },
              {
                path: 'exchange_rates',
                name: 'financial_setup-exchange_rates',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Administration - EXCHANGE RATES' },
              },
              {
                path: 'eod_processing',
                name: 'financial_setup-eod_processing',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Administration - EOD PROCESSING' },
              },
              {
                path: 'authorisation_holds',
                name: 'financial_setup-authorisation_holds',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title: 'Pinkapple Administration - AUTHORISATION HOLDS',
                },
              },
              {
                path: 'rates',
                name: 'financial_setup-rates',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Administration - RATES' },
              },
              {
                path: 'currency',
                name: 'financial_setup-currency',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Administration - CURRENCY' },
              },
              {
                path: 'risk_levels',
                name: 'financial_setup-risk_levels',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Administration - RISK LEVELS' },
              },
              {
                path: 'accounting',
                name: 'financial_setup-accounting',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Administration - ACCOUNTING' },
              },
            ],
          },
          {
            path: 'organisation',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Administration  - Organisation' },
            children: [
              {
                path: '',
                name: 'administration-organisation',
                redirect: { name: 'financial_setup-business_unit' },
              },
              {
                path: 'business_unit',
                name: 'financial_setup-business_unit',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title: 'Pinkapple Administration -  Business Units',
                },
              },
            ],
          },
          {
            path: 'products',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Administration  - Products' },
            children: [
              {
                path: '',
                name: 'administration-products',
                redirect: { name: 'products-loan_products' },
              },
              {
                path: 'loan_products',
                name: 'products-loan_products',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Products - LOAN PRODUCTS' },
              },
              {
                path: 'deposit_products',
                name: 'products-deposit_products',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Products - DEPOSIT PRODUCTS' },
              },
            ],
          },
          {
            path: 'apps',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Administration - Apps' },
            children: [
              {
                path: '',
                name: 'administration-apps',
                redirect: { name: 'administration-apps-apps' },
              },
              {
                path: 'apps',
                name: 'administration-apps-apps',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: { title: 'Pinkapple Products - LOAN PRODUCTS' },
              },
            ],
          },
          {
            path: 'fields',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Administration - Fields' },
            children: [
              {
                path: '',
                name: 'administration-fields',
                redirect: {
                  name: 'administration-fields-clients_field_controler',
                },
              },
              {
                path: 'clients_field_controler',
                name: 'administration-fields-clients_field_controler',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title:
                    'Pinkapple Administration - Fields - CLIENTS FIELD CONTROLER',
                },
              },
              {
                path: 'groups_field_controler',
                name: 'administration-fields-groups_field_controler',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title:
                    'Pinkapple Administration - Fields - GROUPS FIELD CONTROLER',
                },
              },
              {
                path: 'credit_arrangements_field_controler',
                name: 'administration-fields-credit_arrangements_field_controler',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title:
                    'Pinkapple Administration - Fields - CREDIT ARRANGEMENTS FIELD CONTROLER',
                },
              },
              {
                path: 'loan_accounts_field_controler',
                name: 'administration-fields-loan_accounts_field_controler',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title:
                    'Pinkapple Administration - Fields - LOAN ACCOUNTS FIELD CONTROLER',
                },
              },
              {
                path: 'deposit_accounts_field_controler',
                name: 'administration-fields-deposit_accounts_field_controler',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title:
                    'Pinkapple Administration - Fields - DEPOSIT ACCOUNTS FIELD CONTROLER',
                },
              },
              {
                path: 'guarantors_field_controler',
                name: 'administration-fields-guarantors_field_controler',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title:
                    'Pinkapple Administration - Fields - GUARANTORS FIELD CONTROLER',
                },
              },
              {
                path: 'assets_field_controler',
                name: 'administration-fields-assets_field_controler',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title:
                    'Pinkapple Administration - Fields - ASSETS FIELD CONTROLER',
                },
              },
              {
                path: 'hr_field_controler',
                name: 'administration-fields-hr_field_controler',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title:
                    'Pinkapple Administration - Fields - HR FIELD CONTROLER',
                },
              },
              {
                path: 'shares_field_controler',
                name: 'administration-fields-shares_field_controler',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title:
                    'Pinkapple Administration - Fields - SHARES FIELD CONTROLER',
                },
              },
              {
                path: 'deposit_products_field_controler',
                name: 'administration-fields-deposit_products_field_controler',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title:
                    'Pinkapple Administration - Fields - DEPOSIT PRODUCTS FIELD CONTROLER',
                },
              },
              {
                path: 'transaction_by_channel_field_controler',
                name: 'administration-fields-transaction_by_channel_field_controler',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title:
                    'Pinkapple Administration - Fields - TRANSACTION BY CHANNEL FIELD CONTROLER',
                },
              },
              {
                path: 'transaction_by_type_field_controler',
                name: 'administration-fields-transaction_by_type_field_controler',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title:
                    'Pinkapple Administration - Fields - TRANSACTION BY TYPE FIELD CONTROLER',
                },
              },
              {
                path: 'users_field_controler',
                name: 'administration-fields-users_field_controler',
                component: () => import('@/layouts/ParentLayout.vue'),
                meta: {
                  title:
                    'Pinkapple Administration - Fields - USERS FIELD CONTROLER',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'access',
        component: () => import('@/layouts/ParentLayout.vue'),
        meta: { title: 'Pinkapple Access' },
        children: [
          {
            path: '',
            name: 'access',
            redirect: { name: 'access-users' },
          },
          {
            path: 'users',
            name: 'access-users',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Access - Users' },
          },
          {
            path: 'roles',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Access - Roles' },
            children: [
              {
                path: '',
                name: 'access-roles',
                redirect: { name: 'access-roles-user_roles_controler' },
              },
              {
                path: 'user_roles_controler',
                name: 'access-roles-user_roles_controler',
                component: () => import('@/views/Access/Roles.vue'),
                meta: { title: 'Pinkapple Access - Users - Roles' },
              },
            ],
          },
        ],
      },
      {
        path: 'communication',
        component: () => import('@/layouts/ParentLayout.vue'),
        meta: { title: 'Pinkapple Communication' },
        children: [
          {
            path: '',
            name: 'communication',
            redirect: { name: 'communication-templates' },
          },
          {
            path: 'templates',
            name: 'communication-templates',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Communication - Templates' },
          },
          {
            path: 'sms',
            name: 'communication-sms',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Communication - SMS' },
          },
          {
            path: 'email',
            name: 'communication-email',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Communication - Email' },
          },
        ],
      },
      {
        path: 'clients',
        component: () => import('@/layouts/ParentLayout.vue'),
        meta: { title: 'Pinkapple Clients' },
        children: [
          {
            path: '',
            name: 'clients',
            redirect: { name: 'individual_clients' },
          },
          {
            path: 'groups',
            name: 'groups',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Groups' },
          },
          {
            path: 'individual_clients',
            name: 'individual_clients',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Groups' },
          },
        ],
      },
      {
        path: 'deposit_accounts',
        component: () => import('@/layouts/ParentLayout.vue'),
        meta: { title: 'Pinkapple Deposit Accounts' },
        children: [
          {
            path: '',
            name: 'deposit_accounts',
            redirect: { name: 'deposit_accounts_definer_definer' },
          },
          {
            path: 'deposit_accounts_definer_definer',
            name: 'deposit_accounts_definer_definer',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Groups' },
          },
          {
            path: 'deposit_accounts_definer_definer_definer',
            name: 'deposit_accounts_definer_definer_definer',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Groups' },
          },
        ],
      },
      {
        path: 'loan_accounts',
        component: () => import('@/layouts/ParentLayout.vue'),
        meta: { title: 'Pinkapple Loan Accounts' },
        children: [
          {
            path: '',
            name: 'loan_accounts',
            redirect: { name: 'loan_accounts_definer' },
          },
          {
            path: 'loan_accounts_definer',
            name: 'loan_accounts_definer',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Groups' },
          },
        ],
      },
      {
        path: 'credit_arrangements',
        component: () => import('@/layouts/ParentLayout.vue'),
        meta: { title: 'Pinkapple Credit Arrangements' },
        children: [
          {
            path: '',
            name: 'credit_arrangements',
            redirect: { name: 'credit_arrangements_definer' },
          },
          {
            path: 'credit_arrangements_definer',
            name: 'credit_arrangements_definer',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Groups' },
          },
        ],
      },
      {
        path: 'shares',
        component: () => import('@/layouts/ParentLayout.vue'),
        meta: { title: 'Pinkapple Shares' },
        children: [
          {
            path: '',
            name: 'shares',
            redirect: { name: 'shares_management_definer' },
          },
          {
            path: 'shares_management_definer',
            name: 'shares_management_definer',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Groups' },
          },
        ],
      },
      {
        path: 'assets',
        component: () => import('@/layouts/ParentLayout.vue'),
        meta: { title: 'Pinkapple Assets' },
        children: [
          {
            path: '',
            name: 'assets',
            redirect: { name: 'asset_management_definer' },
          },
          {
            path: 'asset_management_definer',
            name: 'asset_management_definer',
            component: () => import('@/layouts/ParentLayout.vue'),
            meta: { title: 'Pinkapple Groups' },
          },
        ],
      },
      {
        path: 'hr',
        name: 'hr',
        component: () => import('@/layouts/ParentLayout.vue'),
        meta: { title: 'Pinkapple HR' },
        // children: [
        //   {
        //     path: '',
        //     redirect: { name: 'individual_clients' },
        //   },
        //   {
        //     path: 'groups',
        //     name: 'groups',
        //     component: () => import('@/layouts/ParentLayout.vue'),
        //     meta: { title: 'Pinkapple Groups' },
        //   },
        //   {
        //     path: 'individual_clients',
        //     name: 'individual_clients',
        //     component: () => import('@/layouts/ParentLayout.vue'),
        //     meta: { title: 'Pinkapple Groups' },
        //   },
        // ],
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/NotFoundPage.vue'),
    name: 'Not-found-Page',
  },
];

export default routes;
