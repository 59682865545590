import '@mdi/font/css/materialdesignicons.css';
import { createVuetify, ThemeDefinition } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import 'vuetify/styles';

const customLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#c9104e',
    'primary-darken-1': '#c9104e',
    secondary: '#2e2d2d',
    'secondary-darken-1': '#2e2d2d',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
};

export default createVuetify({
  components,
  directives,
  defaults: {
    global: {
      styles: [{ fontFamily: 'Plus Jakarta Sans, sans-serif !important' }],
    },
    VContainer: { fluid: true },
  },
  icons: {
    defaultSet: 'mdi',
  },
  theme: {
    defaultTheme: 'customLightTheme',
    themes: { customLightTheme },
  },
});
